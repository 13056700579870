<template>
  <div class="scheme-contain">
    <div class="drug-box">
      <Form :label-width="180">
        <Row>
          <Col span="8" offset="7">
            <Form-item label="类型：">
              <Select placeholder="" v-model="addForm.drugTypeId" @on-change="handleType">
                  <Option v-for="item in typeList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </Form-item>
            <Form-item label="名称：">
              <Select placeholder="请选择" v-model="drugName" @on-change="handleName" @click.native="handleNameInfo">
                <Option v-for="item in drugList" :value="item.value" :key="item.value">{{ item.label }}</Option>
              </Select>
            </Form-item>
            <Form-item label="使用方法：">
              <Input v-model="addForm.drugWay" type="textarea" placeholder="举例：一日3次，早2粒中1粒晚1粒..." :maxlength="100"></Input>
            </Form-item>
            <Form-item label="使用开始时间：">
              <Date-picker type="date" :options="options" placeholder="选择日期" @on-change="changeTime" @on-clear="handleStartTime" :value="addForm.startTime"></Date-picker>
            </Form-item>
            <!-- <Form-item label="已服数量：">
              <Input placeholder="请输入已服数量..." v-model="addForm.have_use"></Input>
            </Form-item>
            <Form-item label="未服数量：">
              <Input placeholder="请输入未服数量..." v-model="addForm.no_use"></Input>
            </Form-item> -->
            <Form-item label="服用依从性：">
              <Select placeholder="请选择" v-model="addForm.complianceId">
                <Option v-for="item in complianceData" :value="item.id" :key="item">{{ item.name }}</Option>
              </Select>
            </Form-item>
            <Form-item label="采购地点：">
              <Input placeholder="请输入采购地点..." type="textarea" v-model="addForm.place" :maxlength="100"></Input>
            </Form-item>
            <Form-item label="寄存数量：">
              <Input placeholder="请输入寄存数量..." type="text" v-model="addForm.num"></Input>
            </Form-item>
          </Col>
        </Row>
        <div class="symbol-box tac">
          <Button type="primary" size="large" @click="handleBack">返回</Button>
          <Button type="success" size="large" @click="handleSave" v-show="power.indexOf('会员管理--编辑会员信息权限') > -1">保存</Button>
        </div>
      </Form>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			drugList: [],
			typeList: [],
			allData: [],
			addForm: {
				drugNameId: '',
				drugTypeId: '',
				drugWay: '',
				startTime: '',
				complianceId: '',
				place: '',
				num: '',
			},
			drugName: '',
			power: [],
			options: {
				disabledDate(date) {
					return date && date.valueOf() >= Date.now();
				},
			},
			complianceData: [],
		};
	},

	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		drugId() {
			return this.$route.params.drug_id;
		},
		userInfo() {
			return this.$store.getters.userInfo;
		},
	},

	created() {
		this.power = this.userInfo.power;
		MemberService.listDrugName().then((data) => {
			this.allData = data;
		});
		MemberService.listDrugType().then((data) => {
			this.typeList = data;
		});
		MemberService.detailDrug({ id: this.drugId }).then((data) => {
			this.drugName = data.name_id;
			this.addForm.drugTypeId = data.type_id;
			this.addForm.startTime = data.start_time;
			// this.addForm.have_use = data.have_use
			// this.addForm.no_use = data.no_use
			this.addForm.complianceId = data.compliance_id;
			this.addForm.place = data.place;
			this.addForm.drugWay = data.way;
			this.addForm.num = data.num;
		});
		this.getComplianceList();
	},

	methods: {
		// 获取服药服从性列表
		getComplianceList() {
			MemberService.complianceList().then((data) => {
				this.complianceData = data;
			});
		},
		changeTime(date) {
			this.addForm.startTime = date;
		},

		validateFormData() {
			if (!this.drugName) {
				this.$Message.warning('请选择成药名称');
				return false;
			} else if (this.addForm.drugWay.length > 100) {
				this.$Message.warning('最多只能输入100字');
				return false;
			} else if (!this.addForm.startTime) {
				this.$Message.warning('请选择时间');
				return false;
			} else if (!this.addForm.complianceId) {
				this.$Message.warning('请选择用药服从性');
				return false;
			} else if (!this.addForm.place) {
				this.$Message.warning('请输入采购地点');
				return false;
			} else if (this.addForm.place.length > 100) {
				this.$Message.warning('最多只能输入100字');
				return false;
			} else {
				return true;
			}
		},

		handleSave() {
			var reqData = {};
			reqData = {
				id: this.drugId,
				member_id: this.memberId,
				type_id: this.addForm.drugNameId,
				way: this.addForm.drugWay,
				start_time: this.addForm.startTime,
				compliance_id: this.addForm.complianceId,
				place: this.addForm.place,
				num: this.addForm.num,
			};
			if (this.validateFormData()) {
				MemberService.updateDrug(reqData).then(() => {
					// this.$Message.success('成药与要素信息修改成功')
					this.$router.go(-1);
				});
			}
		},

		handleBack() {
			this.$router.go(-1);
		},

		init() {
			this.allData.forEach((item) => {
				if (item.value === this.addForm.drugTypeId) {
					this.drugList = item.children;
					return;
				} else {
					this.addForm.drugNameId = '';
				}
			});
		},
		handleType(value) {
			this.addForm.drugTypeId = value;
			this.init();
		},
		handleNameInfo() {
			if (!this.addForm.drugTypeId) {
				this.drugList = [];
				this.$Message.warning('请先选择类型');
			}
		},
		handleName(value) {
			this.addForm.drugNameId = value;
		},
		handleStartTime() {
			this.addForm.startTime = '';
		},
	},
};
</script>
<style lang="css" scoped>
.drug-box {
  padding-top: 20px;
}
</style>
